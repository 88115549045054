<script>
  import { Link } from "svelte-routing";
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";
  import HeroSmall from "../components/Hero/HeroSmall.svelte";
  import { onMount } from "svelte";
  import { supabase } from 'supabaseClient';

  const patternVue = "/assets/img/celular.png";
  const legalbot = "/assets/img/legalbot.png";
  const softplan = "/assets/img/softplan.png";

  let text = "Resultados Tangíveis, Impacto Duradouro.";
  let currentText = "";

  onMount(() => {
    animateText();
  });

  function animateText() {
    const interval = 50;
    let index = 0;

    const animation = setInterval(() => {
      currentText = text.substring(0, index + 1);
      index++;

      if (index === text.length) {
        clearInterval(animation);
      }
    }, interval);
  }


  async function handleSubmit(event) {
    event.preventDefault();

    const form = event.target;

    let formData = {
      nome: form.name.value,
      email: form.email.value,
      telefone: form.phone.value,
      menssagem: form.message.value,
    };

    try {
      //const { error } = await supabase.from('formulario').insert(formData);

      if (error) {
        console.error('Error submitting form:', error.message);
      } 
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  function changeOpacity(element, opacityClass) {
      element.classList.remove('opacity-100', 'opacity-75', 'opacity-50', 'opacity-25', 'opacity-0');
      element.classList.add(opacityClass);
    }
</script>

<link rel="stylesheet" href="node_modules/animate.css/animate.min.css">

<IndexNavbar />

<section class="bg-gradient pt-16 items-center flex h-screen max-h-860-px relative">
  <div class="container mx-auto items-center flex flex-wrap relative">
    <div class="w-full md:w-6/12 lg:w-6/12 xl:w-6/12 px-4">
      <div class="pt-32 sm:pt-0">
        <h2 class="text-10xl text-white">
          FOCAMOS EM <span class="font-semibold"> RESULTADOS</span>
        </h2>
        <p class="mt-4 text-lg leading-relaxed text-white">
          {currentText}
        </p>
      </div>
    </div>
    <div class="ml-auto w-5/12 img-hero">
      <img
        alt="Person using phone"
        class="rounded-full bg-white p-6"
        height="500"
        src="{patternVue}"
        style="aspect-ratio: 1 / 1; object-fit: cover;"
        width="500"
      />
    </div>
  </div> 
</section>




<section class="justify-center text-center flex flex-wrap mt-10 pb-6">
  <div class="w-full md:w-6/12 px-12 md:px-4" id="clientes">
    <h3 class="font-semibold text-5xl">
      <i class="fas fa-medal"></i>
    </h3>
    <h2 class="font-semibold text-3xl">Excelência em Software</h2>
    <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
      Conheça algumas das empresas que confiam em nossas soluções de software personalizadas para impulsionar seu sucesso digital.
    </p>
      <div class="flex items-center justify-center group transition-transform transform hover:scale-110 space-x-8">
      <div class="client-logo-container">
        <img src={legalbot} alt="Logo Cliente 1" class="client-logo opacity-50 group-hover:opacity-100 filter brightness-80 group-hover:brightness-100 h-32" onmouseout="changeOpacity(this, 'opacity-50')">
      </div>
      <div class="client-logo-container">
        <img src={softplan} alt="Logo Cliente 2" class="client-logo opacity-50 group-hover:opacity-100 filter brightness-80 group-hover:brightness-100 h-32" onmouseout="changeOpacity(this, 'opacity-50')">
      </div>
  
    </div>
  </div>
  <div class="container mx-auto overflow-hidden" id="servicos">
    <div class="flex flex-wrap items-center ">
      <div class="w-full md:w-6/12 px-4 mr-auto ml-auto mt-10 pb-6">
        <div class="justify-center flex flex-wrap relative">
          <div class="my-4 w-full lg:w-6/12 px-4">
              <div
                class="bg-green shadow-lg rounded-full  text-center p-8"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-20 h-10 mx-auto p-2 bg-white"
                  src="/assets/img/Node.png"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  Node.js
                </p>
              </div>
              <div class="bg-lightBlue-500 shadow-lg rounded-full text-center p-8 mt-8">
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="/assets/img/go.png"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  Golang
                </p>
              </div>
              <div
                class="bg-blueGray-700 shadow-lg rounded-full text-center p-8 mt-8"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/nextjs.jpg"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  NextJS
                </p>
              </div>
          </div>
          <div class="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
              <div class="bg-orage shadow-lg rounded-full text-center p-8">
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="/assets/img/rust.png"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  Rust
                </p>
              </div>
              <div class="bg-red-700 shadow-lg rounded-full text-center p-8 mt-8">
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/angular.jpg"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  Angular
                </p>
              </div>
              <div
                class="bg-yellow-500 shadow-lg rounded-full text-center p-8 mt-8"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="/assets/img/python.png"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  Python
                </p>
              </div>
          </div>
        </div>
      </div>
  
      <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
        <div
          class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-darkBlue"
        >
          <i class="fas fa-hammer text-xl"></i>
        </div>
        <h3 class="text-3xl mb-2 font-semibold leading-normal">
          Outsourcing de Equipes
        </h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
           Com uma experiência sólida, garantimos soluções que não apenas 
           atendem às suas necessidades atuais, mas também se adaptam de forma 
           eficaz às demandas em constante evolução do mercado.
        </p>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
          Nós criamos soluções para gerar seu melhor resultado.
        </p>
        <div class="block pb-6">
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            Back-end
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            Front-end
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            Cloud
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            Modelagem de Dados
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            BigData
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            Business Intelligence
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            e +
          </span>
        </div>
      </div>
      
    </div>
  </div>
</section>

<section class="justify-center text-center flex flex-wrap mt-10 pb-6" id="inteligencia-negocios">
  <div class="container mx-auto overflow-hidden">
    <div class="flex flex-wrap items-center ">
      <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto">
        <div
          class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-darkBlue"
        >
        <i class="text-2xl fas fa-binoculars"></i>
        </div>
        <h3 class="text-3xl mb-2 font-semibold leading-normal">
          Inteligência de Negócios
        </h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
          Capacitamos sua empresa a compreender tendências, antecipar desafios e
          impulsionar o sucesso através de informações inteligentes e acionáveis. 
          Transforme dados em resultados tangíveis com nossa expertise em inteligência
           de negócios.
        </p>
      </div>
      <div class="w-full md:w-6/12 px-4 mr-auto ml-auto mt-10 pb-6">
        <div class="justify-center flex flex-wrap relative">
          <div class="my-4 px-4 lg:mt-16">
            <img
            alt="..."
            class="shadow-md rounded-full max-w-full mx-auto p-2 bg-darkBlue hidden sm:block"
            src="/assets/img/graph.jpg"
          />
        </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="justify-center text-center flex flex-wrap pb-6" id="encomenda">
  <div class="container mx-auto overflow-hidden">
    <div class="flex flex-wrap items-center ">
      <div class="w-full md:w-6/12 px-4 mr-auto ml-auto pb-6">
        <div class="justify-center flex flex-wrap relative">
          <div class="my-4 px-4 lg:mt-16">
            <img
            alt="..."

            class="shadow-md rounded-full max-w-full mx-auto p-2 bg-darkBlue hidden sm:block"
            src="/assets/img/pc.jpg"
          />
        </div>
        </div>
      </div>
      <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto">
        <div
          class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-darkBlue"
        >
        <i class="text-2xl fas fa-laptop"></i>
        </div>
        <h3 class="text-3xl mb-2 font-semibold leading-normal">
          Software sob ecomenda
        </h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
          Com uma abordagem colaborativa, nossa equipe trabalha em estreita colaboração
          com você para entender os desafios do seu negócio e entregar uma solução eficiente, 
          escalável e totalmente adaptada às suas operações. 
        </p>
      </div>
    </div>
  </div>
</section>

<section class="pb-16 bg-darkBlue relative pt-32 mt-10" id="contato">
  <div class="container mx-auto flex justify-center">
    <div class="w-full max-w-md bg-white shadow rounded-lg -mt-48 py-8 px-6 sm:px-8 md:px-12 relative z-10">
      <div class="w-full text-center">
        <h3 class="font-semibold text-3xl">
          Entre em Contato Conosco
        </h3>
        <p class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
          Estamos ansiosos para ouvir de você! Preencha o formulário abaixo para entrar em contato. Ficaremos felizes em ajudar.
        </p>
        <form on:submit={handleSubmit} class="flex flex-col mt-6">
          <div class="mb-4">
            <label for="name" class="block text-sm font-semibold text-blueGray-600">Nome Completo</label>
            <input type="text" id="name" name="name" placeholder="Seu Nome" class="w-full px-4 py-2 mt-2 border border-blueGray-300 rounded-md focus:outline-none focus:border-blue-500" required />
          </div>
          <div class="mb-4">
            <label for="email" class="block text-sm font-semibold text-blueGray-600">E-mail</label>
            <input type="email" id="email" name="email" placeholder="Seu E-mail" class="w-full px-4 py-2 mt-2 border border-blueGray-300 rounded-md focus:outline-none focus:border-blue-500" required />
          </div>
          <div class="mb-4">
            <label for="phone" class="block text-sm font-semibold text-blueGray-600">Telefone</label>
            <input type="tel" id="phone" name="phone" placeholder="Seu Telefone" class="w-full px-4 py-2 mt-2 border border-blueGray-300 rounded-md focus:outline-none focus:border-blue-500" required />
          </div>
          <div class="mb-4">
            <label for="message" class="block text-sm font-semibold text-blueGray-600">Mensagem</label>
            <textarea id="message" name="message" rows="4" placeholder="Digite sua mensagem aqui..." class="w-full px-4 py-2 mt-2 border border-blueGray-300 rounded-md focus:outline-none focus:border-blue-500" required></textarea>
          </div>
          <button type="submit" class="bg-darkBlue hover:bg-blue-600 text-white font-bold px-6 py-3 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800 uppercase text-sm transition-all duration-150">
            Enviar Mensagem
          </button>
        </form>
        <div class="text-center mt-8"></div>
      </div>
    </div>
  </div>
</section>

<Footer />
