<script>
  let date = new Date().getFullYear();

  function scrollToSection(id) {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setNavbarOpen();
    }
  }

</script>

<footer class="relative bg-blueGray-200 pt-8 pb-6">
  <div
    class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
    style="transform: translateZ(0);"
  >
  </div>
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap text-center lg:text-left">
      <div class="w-full lg:w-6/12 px-4">
        <h4 class="text-3xl font-semibold">Ficou Interessado?
          Entre em contato
          </h4>
        <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
          Temos certeza que nossa equipe pode te ajudar a construir o futuro da sua empresa.
        </h5>
        <div class="mt-6 lg:mb-0 mb-6">
          <a href="https://www.linkedin.com/company/jaguati">
            <button
            class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
            type="button"
          >
            <i class="fab fa-linkedin"></i>
          </button>
          </a>
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="flex flex-wrap items-top mb-6">
          <div class="w-full lg:w-4/12 px-4 ml-auto">
            <span
              class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
            >
              Links
            </span>
            <ul class="list-unstyled">
              <li>
                <a
                  class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                  href="https://www.linkedin.com/company/jaguati/about/"
                >
                  Sobre nós
                </a>
              </li>
              <li>
                <a
                  class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                  href="https://www.linkedin.com/company/jaguati/jobs/"
                >
                  Trabalhe Conosco
                </a>
              </li>
            </ul>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <span
              class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
            >
              Nossas Soluções
            </span>
          
            <ul class="list-unstyled">
              <li>
                <a
                  class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm cursor-pointer"
                  on:click={() => scrollToSection('servicos')}
                >
                 Outsourcing de Equipes
                </a>
              </li>
              <li>
                <a
                  class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm cursor-pointer"
                  on:click={() => scrollToSection('inteligencia-negocios')}
                >
                  Inteligência de Negócios
                </a>
              </li>
              <li>
                <a
                  class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm cursor-pointer"
                  on:click={() => scrollToSection('encomenda')}
                >
                Produção de Software sob Encomenda
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-6 border-blueGray-300" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4 mx-auto text-center">
        <div class="text-sm text-blueGray-500 font-semibold py-1">
          JaguaTI© - {date}
        </div>
      </div>
    </div>
  </div>
</footer>
