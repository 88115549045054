<script>
  import { onMount } from "svelte";

  const logo = "/jaguati.svg";
  let navbarOpen = false;

  function setNavbarOpen() {
    if(window.innerWidth <= 1024){
      navbarOpen = !navbarOpen;
    }
  }

  function handleScroll() {
    const scrollTop = window.scrollY;
    const isTransparent = scrollTop === 0;

    const navbar = document.querySelector(".navbar");
    if (navbar) {
      if (isTransparent) {
        navbar.classList.add("bg-transparent", "shadow-none");
        navbar.classList.remove("bg-darkBlue");
      } else {
        navbar.classList.remove("bg-transparent", "shadow-none");
        navbar.classList.add("bg-darkBlue");
      }
    }
  }

  function scrollToSection(id) {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setNavbarOpen();
    }
  }

  onMount(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
</script>

<nav class="top-0 fixed z-50 w-full bg-transparent px-4 py-2 navbar-expand-lg navbar">
  <div class="container mx-auto flex items-center justify-between">
    <div class="flex items-center">
      <img
        loading="lazy"
        alt="logo"
        src={logo}
        class="w-10 md:w-40 lg:w-50 h-10 md:h-40 lg:h-50 object-contain object-center overflow-hidden shrink-0 max-w-full mr-2"
      />
      <div class="text-white text-xl font-bold whitespace-nowrap my-auto">
        JAGUA<span style="color:#b8bdd1ff">TI</span>
      </div>
    </div>
    <div class="lg:hidden">
      <button
        class="text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent outline-none focus:outline-none"
        on:click={setNavbarOpen}
      >
        <i class="fas fa-bars text-white"></i>
      </button>
    </div>
    <div class="lg:flex flex-grow items-center hidden">
      <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
        <li class="flex items-center mr-4">
          <a class="text-white underline-on-hover cursor-pointer" on:click={() => scrollToSection('clientes')}>
            clientes
          </a>
        </li>
        <li class="flex items-center mr-4">
          <a class="text-white underline-on-hover cursor-pointer" on:click={() => scrollToSection('servicos')}>
            serviços
          </a>
        </li>
        <li class="flex items-center">
          <button
            class="cursor-pointer bg-white text-blueDark active:text-white text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150"
            type="button"
            on:click={() => scrollToSection('contato')}
          >
            Contato
          </button>
        </li>
      </ul>
    </div>
  </div>

  <!-- Mobile Menu -->
  <div class="lg:none" style="{navbarOpen ? 'display: block;' : 'display: none;'}">
    <ul class="list-none text-center">
      <li class="my-2">
        <a class="text-white underline-on-hover cursor-pointer" on:click={() => scrollToSection('clientes')}>
          clientes
        </a>
      </li>
      <li class="my-2">
        <a class="text-white underline-on-hover cursor-pointer" on:click={() => scrollToSection('servicos')}>
          serviços
        </a>
      </li>
      <li class="my-2">
        <button
          class="cursor-pointer bg-white text-blueDark active:text-white text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none"
          type="button"
          on:click={() => scrollToSection('contato')}
        >
          Contato
        </button>
      </li>
    </ul>
  </div>
</nav>
